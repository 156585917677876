body {
	background-color: #eaeaea;
}

.ui.fixed.menu {
	background-image: linear-gradient(
		135deg,
		rgb(24, 42, 115) 0%,
		rgb(33, 138, 174) 69%,
		rgb(32, 167, 172) 89%
	);
}

.ui.main.container {
	margin-top: 9.5em;
}

* {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.masthead {
	position: relative; /* 로고의 절대 위치를 위한 기준점 */
	display: flex;
	flex-direction: column; /* 요소들을 세로로 정렬 */
	align-items: center;
	justify-content: center; /* 세로 중앙 정렬 */
	background-image: url('./1.png') !important; /* 이미지 경로 확인 */
	background-size: cover !important; /* 컨테이너에 맞게 이미지 크기 조정, !important로 우선순위 부여 */
	background-repeat: no-repeat; /* 이미지 반복 방지 */
	background-position: center center !important; /* 이미지를 중앙에 배치 */
	min-height: 100vh; /* 최소 높이 설정, 내용이 더 많으면 늘어날 수 있도록 */
	padding: 4em 0;
}

.icon-spacing {
  margin-right: 10px;
}



.logo-top-left {
  position: absolute; /* 부모 요소 대비 절대 위치 */
  top: 30px; /* 위쪽 가장자리에서부터의 거리 */
  left: 30px; /* 왼쪽 가장자리에서부터의 거리 */
  width: 200px; /* 원하는 로고의 가로 크기 */
  height: auto; /* 세로 크기는 가로 크기에 맞춰 자동으로 조정 */
  z-index: 1000; /* 다른 요소들 위에 오도록 z-index 설정 */
}


.logo-container img {
  width: 20%; /* 부모 컨테이너의 50% 크기 */
  height: auto; /* 세로 크기는 가로 크기에 맞춰 자동으로 조정 */
}


.react-datepicker-wrapper {
	width: 100%;
}

.react-calendar {
	width: 100%;
	border: none;
	box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.gray-divider {
  border-top: 1px solid gray  !important; /* 상단 테두리 색상을 회색으로 설정 */
	margin-top: 2em !important; /* 위쪽 여백 설정 */
	margin-bottom: 2em !important; /* 아래쪽 여백 설정 */
}

.custom-large-button {
  font-size: 3em !important; /* 글꼴 크기 증가 */
  padding: 20px 40px  !important; /* 패딩을 늘려 버튼 크기 증가 */
}

.masthead h1.ui.header {
	font-size: 10em;
}

.masthead h2.ui.header {
	font-size: 4em;
}


/* 1920x1080 해상도를 위한 스타일 */
@media screen and (max-width: 1920px) {
  .h2responsive-text {
    font-size: 2.5rem !important;
  }
	.h1responsive-text {
    font-size: 7rem !important; /* 원하는 글씨 크기로 조정 */
  }
	.divresponsive-text {
    font-size: 1rem !important; /* 원하는 글씨 크기로 조정 */
  }
	.div2responsive-text {
    font-size: 1.5rem !important; /* 원하는 글씨 크기로 조정 */
  }
}

.menu-item {
  margin-bottom: 1em; /* 기존 간격의 1.5배 */
}

@media screen and (max-width: 1000px) {
  .icon-spacing {
    display: none; /* 아이콘 숨기기 */
  }
  .menu-item {
    font-size: 10px; /* 글자 크기 줄이기 */
  }
}

.tooltip {
  position: relative;
  display: block; /* 또는 flex, grid에 따라 조정 */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 300px; /* 툴팁 너비 조정 */
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -100px; /* 툴팁 너비의 절반 */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.margin {
  margin-top: 10px; /* 위쪽 간격 조절 */
  margin-bottom: 10px; /* 아래쪽 간격 조절 */
  margin-right: 3px;
}

.pagination {
  display: flex; /* Flexbox를 사용하여 항목을 가로로 정렬 */
  list-style-type: none; /* 기본 목록 스타일 제거 */
  padding: 0; /* 기본 패딩 제거 */
  justify-content: center; /* 페이지네이션을 중앙에 위치 */
  gap: 10px; /* 버튼 사이의 간격 */
}

.page-item a {
  border: 1px solid #ddd; /* 테두리 스타일 */
  padding: 5px 10px; /* 내부 패딩 */
  text-decoration: none; /* 링크 밑줄 제거 */
  color: #007bff; /* 링크 색상 */
}

.page-item a:hover {
  background-color: #f8f9fa; /* 호버 시 배경색 변경 */
}

.page-item.active a {
  background-color: #007bff; /* 활성 페이지의 배경색 */
  color: white; /* 활성 페이지의 텍스트 색상 */
}
